<template>
  <div>
    <div class="checkout-account" v-if="screen === 'DESKTOP'">
      <h4>Register</h4>
      <validation-observer ref="accountValidation">
        <b-form class="mt-2">
          <b-row>
            <!-- first name -->
            <b-col cols="12" md="6" xl="6">
              <b-form-group
                label="Name"
                label-for="firstName"
              >
                <validation-provider #default="{ errors }" rules="required" name="Name">
                  <b-input-group 
                    class="input-group-merge" 
                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                    <b-form-input
                      id="firstName"
                      v-model="firstName"
                      placeholder="Name"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" xl="6">
              <b-form-group
                label="Surename"
                label-for="lastName"
              >
                <validation-provider #default="{ errors }" rules="required" name="lastName">
                  <b-input-group 
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                    <b-form-input
                      id="lastName"
                      v-model="lastName"
                      placeholder="Surename"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- email -->
          <b-row class="mt-1">
            <b-col cols="12" md="12" xl="12">
              <b-form-group
                label="Email"
                label-for="userEmail"
              >
                <validation-provider #default="{ errors }" rules="required|email" name="email">
                  <b-input-group 
                    class="input-group-merge" 
                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                      <b-form-input
                        id="userEmail"
                        type="email"
                        v-model="userEmail"
                        placeholder="Email"
                        autocomplete="off"
                        :state="errors.length > 0 ? false:null"
                      />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          
          <!-- password -->
          <b-row class="mt-1">
            <b-col cols="12" md="12" xl="12">
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider #default="{ errors }" rules="required" name="password">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="password"
                      :type="passwordFieldType"
                      v-model="password"
                      placeholder="Password"
                      autocomplete="off"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text :class="{ 'error-form-btn': errors.length > 0 }">
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

    <div class="checkout-card-mobile" v-if="screen === 'MOBILE'">
        <div class="title">
          <h4>Register</h4>
        </div>

        <validation-observer ref="accountValidation">
          <b-form class="mt-2"  @submit.prevent>
            <div class="mobile-body">
              <b-row>
                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    label="Name"
                    label-for="firstName"
                  >
                    <validation-provider #default="{ errors }" rules="required" name="Name">
                      <b-input-group 
                        class="input-group-merge" 
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                        <b-form-input
                          id="firstName"
                          v-model="firstName"
                          placeholder="Name"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surename"
                    label-for="lastName"
                  >
                    <validation-provider #default="{ errors }" rules="required" name="lastName">
                      <b-input-group 
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                        <b-form-input
                          id="lastName"
                          v-model="lastName"
                          placeholder="Surename"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- email -->
              <b-row class="mt-1">
                <b-col cols="12" md="12" xl="12">
                  <b-form-group
                    label="Email"
                    label-for="userEmail"
                  >
                    <validation-provider #default="{ errors }" rules="required|email" name="email">
                      <b-input-group 
                        class="input-group-merge" 
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                          <b-form-input
                            id="userEmail"
                            type="email"
                            v-model="userEmail"
                            placeholder="Email"
                            autocomplete="off"
                            :state="errors.length > 0 ? false:null"
                          />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <!-- password -->
              <b-row class="mt-1">
                <b-col cols="12" md="12" xl="12">
                  <b-form-group
                    label="Password"
                    label-for="password"
                  >
                    <validation-provider #default="{ errors }" rules="required" name="password">
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="password"
                          :type="passwordFieldType"
                          v-model="password"
                          placeholder="Password"
                          autocomplete="off"
                          :state="errors.length > 0 ? false:null"
                        />
                        <b-input-group-append is-text :class="{ 'error-form-btn': errors.length > 0 }">
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div>
              <check-box-confirm ref="checkboxConfirm"/>
            </div>

            <div class="step-buttons-mobile">
              <button class="lab-btn back" type="button" @click="prev()">Back</button>
              <button class="lab-btn next valid" type="submit" @click="onSubmit()">
                Next
              </button>
            </div>
          </b-form>
        </validation-observer>
    </div>
  </div>
</template>

<script>
import { G25_CHECKOUT_STEPS, TRAIT_CHECKOUT_STEPS } from '@/common/config';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

import { createHelpers } from 'vuex-map-fields';

import CheckBoxConfirm from '../G25Steps/others/CheckBoxConfirm.vue';

const { mapFields } = createHelpers({
  getterType: 'g25Checkout/getUserDataField',
  mutationType: 'g25Checkout/updateUserDataField',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CheckBoxConfirm,
  },
  props: {
    screen: {
      type: String,
      default: 'DESKTOP',
    },
    checkoutModule: {
      type: String,
      default: 'g25Checkout',
    },
    currentRouteData: {
      type: Object,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
    }
  },
  methods: {
    onSubmit() {
      this.$refs.accountValidation.validate().then(success => {
        if (success) {
          if (this.checkoutModule === 'g25Checkout') {
            if (this.$store.state.g25Checkout.createOrderData.isConfirmPrivacyPalicy === false) {
              this.$refs.checkboxConfirm.validateCheckbox();
              return;
            }
            
            this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.CONFIRM}/${this.currentRouteData.productCode}`);
          } else {
            if (this.$store.state.traitCheckout.createOrderData.isConfirmPrivacyPalicy === false) {
              this.$refs.checkboxConfirm.validateCheckbox();
              return;
            }
            
            this.$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.CONFIRM}/${this.currentRouteData.productCode}`);
          }
        }
      });
    },
    prev() {
      this.$router.back();
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapFields([
      'userEmail',
      'firstName',
      'lastName',
      'password',
    ]),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-account {
  padding: 1rem 1.5rem;
  .form-control:focus {
    font-size: 0.8rem;
    border: 2px solid var(--lab-minted-elegance-color);
  }
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .text-danger {
    font-size: 0.7rem;
  }
}

.checkout-card-mobile {
  .mobile-body {
    padding: 1rem 2rem;
    min-height: 400px;
  }
  .title {
    text-align: center;
  }
}
</style>
