var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.screen === 'DESKTOP')?_c('div',{staticClass:"checkout-account"},[_c('h4',[_vm._v("Register")]),_c('validation-observer',{ref:"accountValidation"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"firstName"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"firstName","placeholder":"Name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3999808255)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Surename","label-for":"lastName"}},[_c('validation-provider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"lastName","placeholder":"Surename","state":errors.length > 0 ? false:null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,39065361)})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"userEmail"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"userEmail","type":"email","placeholder":"Email","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2156080950)})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"placeholder":"Password","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{class:{ 'error-form-btn': errors.length > 0 },attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2094203245)})],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.screen === 'MOBILE')?_c('div',{staticClass:"checkout-card-mobile"},[_vm._m(0),_c('validation-observer',{ref:"accountValidation"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"mobile-body"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"firstName"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"firstName","placeholder":"Name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3999808255)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Surename","label-for":"lastName"}},[_c('validation-provider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"lastName","placeholder":"Surename","state":errors.length > 0 ? false:null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,39065361)})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"userEmail"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"userEmail","type":"email","placeholder":"Email","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2156080950)})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"placeholder":"Password","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{class:{ 'error-form-btn': errors.length > 0 },attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2094203245)})],1)],1)],1)],1),_c('div',[_c('check-box-confirm',{ref:"checkboxConfirm"})],1),_c('div',{staticClass:"step-buttons-mobile"},[_c('button',{staticClass:"lab-btn back",attrs:{"type":"button"},on:{"click":function($event){return _vm.prev()}}},[_vm._v("Back")]),_c('button',{staticClass:"lab-btn next valid",attrs:{"type":"submit"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Next ")])])])],1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h4',[_vm._v("Register")])])}]

export { render, staticRenderFns }